var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    attrs: {
      size: "sm",
      type: "text",
      placeholder: "Pagina",
      formatter: _vm.formatter,
      readonly: _vm.readonly,
    },
    on: {
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        $event.preventDefault()
        return _vm.onInput.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }